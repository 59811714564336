@value mBreakpointLg, mBreakpointLtLg from '~#src/app/assets/styles/breakpoints.values.css';

.mega-menu-content {
  composes: flex from global;
  padding: 40px 50px 50px;
  margin: 0 -40px;
  background: #f7f7f7;
  pointer-events: all;

  /* Ensure the menu content itself is scrollable by capping it's height. */
  /* Subtract 48px for the bottom bar, 64px for the top bar, and 24px for some breathing room  */
  max-height: calc(100vh - 48px - 64px - 24px);
  overflow: auto;
  overscroll-behavior: contain;
}

.mega-menu-main-column {
  composes: flex flex-col from global;
  display: grid;
  width: 75%;
}
.mega-menu-main-column-header {
  composes: flex from global;
  margin-bottom: 24px;
  align-items: baseline;
}

.mega-menu-main-column-header-title {
  font-size: 30px;
  font-weight: 800;
  line-height: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 40px;
}

.mega-menu-main-column-header-text {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0px;
}

.mega-menu-main-column-heading-caret {
  composes: ml-1 from global;
  flex-shrink: 0;
}

.mega-menu-main-column-body {
  composes: flex flex-row from global;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.mega-menu-right-column {
  composes: flex flex-col items-center justify-center px-4 from global;
  background-color: var(--color-support-marketing-mango);

  /* Hack: add negative margins to negate the padding added by the container */
  margin: -40px -50px -50px 0;
}

.mega-menu-right-column-title {
  composes: mb-2 text-center from global;
}

.mega-menu-right-column-body {
  composes: mb-3 text-center from global;
}

.mega-menu-link-list {
  composes: list-reset m-0 from global;
  padding: 0;
  /* Ensure the grid columns do not expand (see: https://datacadamia.com/web/css/grid/overflow) */
  min-width: 0px;
}

.mega-menu-link-list-section {
  composes: list-reset m-0 from global;
  padding-bottom: 20px;

  &:last-child {
    padding-bottom: 0;
  }
}

.mega-menu-link-list-item {
  margin-bottom: 4px;

  & a {
    width: 100%;
    font-size: 16px;
    color: var(--c-black-75) !important;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }
}

.mega-menu-link-list-heading {
  composes: mega-menu-main-column-header-title;
  font-size: 20px;
  width: fit-content;
  border-bottom: 1px solid currentColor;
  margin-bottom: 7px;
}

.cta {
  composes: flex flex-col from global;
  gap: 16px;
}

.link {
  text-decoration: underline;
}
