@value mBreakpointLg, mBreakpointLtLg from '~#src/app/assets/styles/breakpoints.values.css';

.wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
  flex-shrink: 0;

  transition: transform 250ms;

  &.hidden {
    transform: translateY(-100%);
  }
}

.header {
  composes: relative from global;
  background: var(--c-white);
  font-size: 16px;
  line-height: 24px;
  z-index: 1;

  &.is-impersonating {
    background: repeating-linear-gradient(
      -55deg,
      #fccc74,
      #fccc74 10px,
      #ffda94 10px,
      #ffda94 20px
    );

    & .bottom-bar-wrapper {
      border-color: #fccc74;
    }
  }
}

.top-bar {
  composes: flex items-center from global;
  height: 64px;
}

.top-bar-wrapper {
  composes: relative from global;
  z-index: 2;
}

.bottom-bar {
  composes: flex items-center from global;
  height: 48px;
}

.bottom-bar-wrapper {
  composes: relative from global;
  z-index: 1;
  border-bottom: 1px solid #eeeeee;
}

.primary-nav {
  composes: flex-auto from global;
  flex-basis: 100%;

  /* Shift the nav left to account for the padding on the first item (6px on smaller devices, 8px on larger) */
  margin-left: -6px;
  margin-right: 6px;

  @media mBreakpointLg {
    margin-left: -8px;
    margin-right: 8px;
  }

  & .nav-list-item {
    cursor: pointer;
    color: var(--c-black-50);

    &:hover {
      color: var(--c-black-100);
      background-color: #f2f2f2;
    }

    &.nav-list-item-active {
      color: var(--c-black-100);
      font-weight: bold;
    }
  }
}

.bottom-nav {
  composes: flex-auto flex items-center from global;
  height: 100%;

  & .nav-list-item {
    /* Margin replaced with padding keep the "tap target" wide while aligning the bottom border with the text */
    padding: 0 8px;
    margin: 0;

    @media mBreakpointLg {
      padding: 0 12px;
    }

    &:not(.nav-list-item-unhoverable):hover,
    &:active,
    &.nav-list-item-active {
      & .nav-list-item-label {
        border-bottom: 3px solid var(--c-black-100);
      }

      & .mega-menu {
        max-height: 100vh;
        box-shadow: 0px 100vh 0px 100vh rgb(0 0 0 / 40%);
        background-color: rgb(0 0 0 / 40%);
      }
    }

    &.nav-list-item-unhoverable .mega-menu {
      /* When the user clicks an item in the mega menu, we temporarily make it unhoverable so that it closes. We */
      /* want it to close immediately, unlike when the user mouses away from the menu, in which case we allow a */
      /* slight delay */
      transition-delay: 0s;
    }
  }

  & .nav-list-item-label {
    border-bottom: 3px solid transparent;
    z-index: 1;

    /* Shift the item down by a pixel so the bottom border on hover covers the bottom border of the header itself  */
    position: relative;
    top: 1px;
  }

  & .clerance-label {
    color: var(--color-shop-orange-400);
  }
}

.secondary-nav {
  composes: bottom-nav;

  /* Shift the secondary nav left to account for the padding on the first item (8px on smaller devices, 12px on larger) */
  margin-left: -8px;
  margin-right: 8px;

  @media mBreakpointLg {
    margin-left: -12px;
    margin-right: 12px;
  }
}

.referrals-and-reviews-nav {
  composes: bottom-nav;
  composes: justify-end from global;
  display: none;

  /* Shift the referrals/reviews nav right to account for the padding on the first item (8px on smaller devices, 12px on larger) */
  margin-right: -8px;
  margin-left: 8px;

  @media mBreakpointLg {
    display: flex;
    margin-right: -12px;
    margin-left: 12px;
  }
}

.tertiary-nav {
  composes: flex-auto from global;
  flex-basis: 100%;

  /* Shift the nav right to account for the padding on the final item */
  margin-left: 8px;
  margin-right: -8px;

  & .nav-list {
    justify-content: flex-end;
    align-items: center;
  }

  & .nav-list-item,
  & .nav-list-item > a {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  & .nav-list-item {
    padding: 0;
  }

  & .nav-list-item > a {
    padding: 8px;
    border-radius: 20px;

    &:hover {
      background-color: var(--c-black-5);
    }
  }
}

.exposed-search {
  & .primary-nav,
  & .tertiary-nav {
    flex-basis: unset;
  }

  & .primary-nav {
    flex-grow: 0;
  }

  & .tertiary-nav {
    flex-grow: 0;
  }
}

.logo {
  composes: flex items-center from global;
  flex-shrink: 0;
  flex-grow: 0;
}

.exposed-logo {
  margin-right: 16px;

  @media mBreakpointLg {
    margin-right: 32px;
  }
}

.search-trigger-button {
  display: flex;
  padding: 10px 8px;
  height: 44px;
  border-radius: 22px;
  background: var(--color-neutrals-100);
  color: var(--color-neutrals-600);
  flex-shrink: 0;
  flex-grow: 1;
  cursor: pointer;

  @media mBreakpointLg {
    margin: 0 32px;
    padding: 10px 16px;
  }
}

.search-trigger-icon {
  margin-right: 8px;
}

.nav-list {
  composes: list-reset m-0 flex from global;
  height: 100%;
}

.nav-list-item {
  composes: flex items-center from global;
  padding: 4px 8px;
  cursor: default;
  white-space: nowrap;
  height: 100%;
  font-size: 15px;

  @media mBreakpointLg {
    font-size: 16px;
    margin-right: 8px;
    padding: 4px 8px;
  }

  &:last-child {
    margin-right: 0;
  }

  & a {
    color: inherit;
  }
}

.nav-list-item-badge {
  composes: absolute flex items-center justify-center text-white from global;
  top: 1px;
  right: 0px;
  min-width: 20px;
  height: 20px;
  background-color: var(--c-error);
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 11px;
  line-height: 100%;
}

.nav-list-item-label {
  composes: flex items-center from global;
  height: 100%;
}

.nav-drop-down-menu {
  composes: list-reset absolute right-0 py-1 from global;
  top: 100%;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-width: 250px;
  display: none;

  & ul {
    padding: 8px 0;
    margin: 0;
    border-bottom: 1px solid #eeeeee;
  }

  & li:first-child ul {
    padding-top: 0;
  }

  & li:last-child ul {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.nav-drop-down-menu-section-title {
  composes: py-1 px-2 from global;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.nav-drop-down-menu-item {
  composes: flex from global;
}

.nav-drop-down-menu-item a {
  position: relative;
  padding: 8px 16px;
  display: flex;
  width: 100%;

  &:hover {
    background: var(--c-black-5);
  }

  & > svg {
    margin-right: 8px;
    flex-shrink: 0;
  }
}

.nav-drop-down-menu-item-activity-indicator {
  composes: absolute from global;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--c-error);
  right: 16px;
  top: 50%;
  margin-top: -4px;
}

.stop-impersonation-link {
  background: repeating-linear-gradient(-55deg, #fccc74, #fccc74 10px, #ffda94 10px, #ffda94 20px);

  &:hover {
    background: repeating-linear-gradient(
      -55deg,
      #ffda94,
      #ffda94 10px,
      #fccc74 10px,
      #fccc74 20px
    ) !important;
  }
}

.inbox-nav-list-item,
.account-nav-list-item,
.reviews-nav-list-item {
  composes: relative from global;
  composes: nav-list-item;
}

.inbox-nav-list-item:hover .inbox-drop-down-menu {
  display: block;
}

.account-nav-list-item:hover .account-drop-down-menu {
  display: block;
}

.reviews-nav-list-item:hover .reviews-drop-down-menu {
  display: block;
}

.reviews-nav-list-item a {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reviews-nav-list-item .nav-list-item-label span {
  margin-right: 8px;
}

.inbox-drop-down-menu,
.account-drop-down-menu,
.reviews-drop-down-menu {
  composes: nav-drop-down-menu;
}

.account-name {
  margin: 0 5px 0 4px;
}

.curation {
  composes: flex-col from global;
  align-items: start;
  justify-items: center;
}

.curation-expert {
  composes: flex items-center from global;
  color: var(--c-black-50);
  font-size: 14px;
  line-height: 18px;
}

.curation-expert-avatar {
  margin-right: 4px;
}

.registration-link {
  color: var(--c-primary) !important;
}

.mega-menu {
  composes: flex absolute from global;
  top: 100%;
  left: 0;
  width: 100%;
  pointer-events: none;
  cursor: default;
  white-space: normal;
  overflow: hidden;
  max-height: 0vh;

  /* These properties are modified when the parent <li> is hovered, but their transition is delayed slightly */
  /* so that users can quickly mouseover the nav item without getting "trapped" in the mega menu */
  transition-property: max-height, box-shadow, background-color;
  transition-timing-function: steps(2, end);
  transition-delay: 0.3s;
}

.mega-menu-content {
  composes: flex from global;
  padding: 32px 40px 40px;
  margin: 0 -40px;
  background: var(--c-white);
  pointer-events: all;

  /* Ensure the menu content itself is scrollable by capping it's height. */
  /* Subtract 48px for the bottom bar, 64px for the top bar, and 24px for some breathing room  */
  max-height: calc(100vh - 48px - 64px - 24px);
  overflow: auto;
  overscroll-behavior: contain;
}

.mega-menu-first-column,
.mega-menu-third-column {
  composes: flex flex-col from global;
  flex-shrink: 0;

  /* 175px for the content (width of images) + 24px of padding + 1px of border */
  flex-basis: 200px;

  @media mBreakpointLg {
    /* 200px for the content (width of images) + 24px of padding + 1px of border */
    flex-basis: 225px;
  }
}

.mega-menu-first-column {
  padding-right: 24px;
  border-right: 1px solid #e1e1e1;
}

.mega-menu-third-column {
  padding-left: 24px;
  border-left: 1px solid #e1e1e1;
}

.mega-menu-main-column {
  composes: flex-auto from global;
  display: grid;

  /* Below the "lg" breakpoint we have 3 columns of links. Above "lg" we have 4. */
  grid-template-columns: repeat(3, 1fr);

  @media mBreakpointLg {
    grid-template-columns: repeat(4, 1fr);
  }
}

.mega-menu-heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--c-black-100) !important;
  margin-bottom: 10px;
}

.mega-menu-heading-caret {
  composes: ml-1 from global;
  flex-shrink: 0;
}

.mega-menu-category-image,
.mega-menu-article-image {
  width: 100%;
  object-fit: cover;

  /* The available/effective width is 175px up to the "lg" breakpoint, and then 200px. These heights maintain a */
  /* consistent aspect ratio. */
  height: 105px;

  @media mBreakpointLg {
    height: 120px;
  }
}

.mega-menu-top-list-image {
  object-fit: contain;
  mix-blend-mode: inherit;
  height: 20px;
  width: 20px;
}

.mega-menu-top-list-image-container {
  composes: bg-black-5 flex-none radius-1 p-0.5 from global;
  width: 28px;
  height: 28px;
  mix-blend-mode: darken;
}

.mega-menu-category-expert-facepile {
  composes: flex justify-center from global;
  flex-direction: row-reverse;
  margin-left: -5px;
  margin-top: -20px;
  height: 40px;
}

.mega-menu-category-expert-avatar {
  margin-right: -10px;
  border: 2px solid #f9f9f9;
}

.mega-menu-category-expert-info {
  composes: text-black text-center from global;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 12px;

  & > strong {
    font-size: 40px;
    line-height: 24px;
    display: block;
    margin-bottom: 12px;
  }
}

.mega-menu-category-path-cta {
  composes: mt-2 from global;
  border-radius: 24px;
}

.mega-menu-link-list {
  composes: list-reset m-0 from global;
  padding: 0 24px 24px;

  /* Ensure the grid columns do not expand (see: https://datacadamia.com/web/css/grid/overflow) */
  min-width: 0px;

  /* Add a left border to every list but those in the first column */
  border-left: 1px solid #e1e1e1;

  /* Below the "lg" breakpoint we have 3 columns */
  /* This uses a "desktop-first" breakpoint because unsetting these styles at the larger breakpoint would be difficult to read */
  @media mBreakpointLtLg {
    &:first-child,
    &:nth-child(3n + 1) {
      border-left: none;

      /* The first column of lists also doesn't have left-padding by default, in case there is no left column in the mega menu */
      /* This left-padding is added back in the case where there is a left-column */
      padding-left: 0;
    }

    /* On the second row (all lists following the 4th), add additional padding to the top */
    &:nth-child(3) ~ .mega-menu-link-list {
      padding-top: 24px;
    }
  }

  /* Same as above, but above the "lg" breakpoint we have 4 columns */
  @media mBreakpointLg {
    &:nth-child(4n + 1) {
      border-left: none;

      /* The first column of lists also doesn't have left-padding by default, in case there is no left column in the mega menu */
      /* This left-padding is added back in the case where there is a left-column */
      padding-left: 0;
    }

    /* On the second row (all lists following the 5th), add additional padding to the top */
    &:nth-child(4) ~ .mega-menu-link-list {
      padding-top: 24px;
    }
  }
}

/* When there is no first column, we remove the padding from the left-most lists of links in the mega menu (see comment above) */
/* This adds it back when there *is* a left column */
.mega-menu-first-column + .mega-menu-main-column .mega-menu-link-list {
  padding-left: 24px;
}

.mega-menu-first-column-link-list,
.mega-menu-third-column-link-list {
  composes: list-reset m-0 from global;
}

.mega-menu-link-list-item {
  margin-bottom: 4px;
  color: var(--c-black-75);

  & a {
    width: 100%;
    color: inherit;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
      color: var(--c-black-100);
    }
  }
}

.mega-menu-link-list-sale-item {
  composes: mega-menu-link-list-item;
  color: #cd3c36;
}

.mega-menu-link-list-clearance-item {
  composes: mega-menu-link-list-item;
  color: var(--color-shop-orange-400);
}

.mega-menu-link-list-heading {
  composes: mega-menu-link-list-item mega-menu-heading;
  margin-bottom: 7px;
}

.mega-menu-articles {
  composes: pb-2 bb-normal from global;
}

.mega-menu-article {
  margin-top: 24px;

  &:first-of-type {
    margin-top: 12px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.mega-menu-article-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-black-100);
}

.mega-menu-top-lists {
  margin-top: 16px;
}

.mega-menu-top-list {
  composes: flex items-center mt-2.5 from global;

  &:first-of-type {
    margin-top: 12px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.mega-menu-top-list-title {
  composes: font-medium text-black ml-1 from global;
  font-size: 16px;
  line-height: 20px;
}
