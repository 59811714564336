.nav-list-item-badge {
  composes: absolute flex items-center justify-center text-white from global;
  top: 1px;
  right: 0px;
  min-width: 20px;
  height: 20px;
  background-color: var(--c-error);
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 11px;
  line-height: 100%;
}

.nav-button {
  composes: p-1 relative from global;

  &:hover {
    border-radius: 20px;
    background-color: var(--c-black-5);
  }
}
